/* Reset HTML */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter';
  font-style: normal;
}
/* Class */
.text-icon-center {
  display: grid;
  grid-template-columns: auto auto;
  grid-column: 30px 1fr;
  gap: 10px;
}

.ant-menu-item {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  transition: none !important;
}

.ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-title {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.ant-menu-item-selected .ant-menu-title-content a,
.ant-menu-submenu-selected .ant-menu-submenu-title .ic-menu-custom,
.ant-menu-item-selected .ic-menu-custom {
  color: #e12b25 !important;
}

.ant-menu-item-active a,
.ant-menu-submenu-active .ant-menu-submenu-title .ic-menu-custom,
.ant-menu-item-active .ic-menu-custom {
  color: #e12b25 !important;
}

.ant-menu-item-active,
.ant-menu-item-selected,
.ant-menu-item-active .ant-menu-title-content {
  color: #e12b25 !important;
}

.ant-menu-submenu-selected .ant-menu-submenu-arrow::after,
.ant-menu-submenu-selected .ant-menu-submenu-arrow::before {
  background: #e12b25 !important;
}

.ant-menu-item:active {
  background: none !important;
}

.sidemenu-layout:hover .ant-menu-submenu-title .ic-menu-custom {
  color: #e12b25 !important;
}

.sidemenu-layout:active .ant-menu-submenu-title {
  background: none !important;
}

.sidemenu-layout:active .ant-menu-submenu-title .ic-menu-custom {
  color: #e12b25 !important;
}

.ant-menu-submenu:hover .ant-menu-submenu:not(:hover) .ant-menu-submenu-arrow::after,
.ant-menu-submenu:hover .ant-menu-submenu:not(:hover) .ant-menu-submenu-arrow::before,
.ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-arrow::after,
.ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-arrow::before {
  background: black !important;
}

.ant-menu-submenu:hover .ant-menu-submenu-selected:not(:hover) .ant-menu-submenu-arrow::after,
.ant-menu-submenu:hover .ant-menu-submenu-selected:not(:hover) .ant-menu-submenu-arrow::before,
.ant-menu-submenu:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-active .ant-menu-submenu-arrow::after,
.ant-menu-submenu-active .ant-menu-submenu-arrow::before,
.ant-menu-submenu .ant-menu-submenu-active .ant-menu-submenu-arrow::after,
.ant-menu-submenu .ant-menu-submenu-active .ant-menu-submenu-arrow::before,
.ant-menu-submenu .ant-menu-submenu-selected .ant-menu-submenu-arrow::after,
.ant-menu-submenu .ant-menu-submenu-selected .ant-menu-submenu-arrow::before {
  background: #e12b25 !important;
}

.ant-menu-submenu-active .ant-menu-title-content,
.ant-menu-item-selected .ant-menu-title-content:hover,
.ant-menu-item-selected .ant-menu-title-content::selection {
  color: #e12b25 !important;
}

.ant-menu-item-only-child:hover {
  color: #e12b25 !important;
}

.ant-menu-item-selected,
ant-menu-item-only-child:hover .ant-menu-title-content,
.ant-menu-submenu-selected {
  color: #e12b25 !important;
}

.ant-menu-title-content .active:hover {
  color: #e12b25 !important;
}

.ant-menu-item-selected,
.ant-menu-item-selected::after,
.ant-menu-inline .ant-menu-item::after {
  background: white !important;
  border-right: 0px !important;
  transition: none;
  transform: none;
}

::selection {
  background: #e12b25 !important;
  color: white;
}
.sparepart-collapse.ant-collapse-icon-position-end
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  top: 35% !important;
}
.ant-input-affix-wrapper:hover {
  border-color: #e12b25 !important;
  outline: none !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper-focused {
  border-color: #e12b25 !important;
  outline: none !important;
  box-shadow: none !important;
}

.ant-input,
.ant-input-affix-wrapper {
  outline: none !important;
  box-shadow: none !important;
}

.ant-input:hover,
.ant-input:focus {
  border-color: #e12b25 !important;
  outline: none !important;
  box-shadow: none !important;
}

.top-menu-nav-dropdown:hover .top-ic-menu {
  color: #e12b25 !important;
}

.inspeksi-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px !important;
  padding: 10px 0px !important;
}

.inspeksi-collapse .ant-collapse-header .ant-collapse-arrow {
  right: 2px !important;
}

.inspeksi-collapse .inspeksi-collapse-header {
  border-bottom: 1px solid #d1d5dc !important;
}

.inspeksi-collapse .ant-collapse-content {
  margin: 0px !important;
}

.custom-date-picker {
  border: 1px solid #d0d5dd !important;
  border-radius: 6px !important;
  outline: none !important;
  box-shadow: none !important;
}

.custom-date-picker:focus,
.custom-date-picker:hover,
.custom-date-picker:active {
  border-color: #e12b25 !important;
  outline: none !important;
  box-shadow: none !important;
}

.custom-date-picker .ant-picker-active-bar {
  background-color: #e12b25 !important;
}

/* .ant-picker-dropdown {
  background: red !important;
} */

.custom-date-picker-dropdown .ant-btn {
  background: #e12b25;
  border: 1px solid;
}

.custom-date-picker-dropdown .ant-btn[disabled] {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #e12b25;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #e12b25;
  border-radius: 2px;
  content: '';
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #ffd6d6;
}

.ant-picker-today-btn {
  color: #e12b25;
}

.ant-picker-today-btn:hover {
  color: #e12b25;
  text-decoration: underline;
}

.ant-picker-today-btn:active {
  color: #e12b25;
}

.custom-checkbox .ant-checkbox .ant-checkbox-inner {
  border: 1px solid #d0d5dd !important;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  border: 1px solid #e12b25 !important;
  background: #feece2 !important;
}
.custom-checkbox:hover .ant-checkbox .ant-checkbox-inner,
.custom-checkbox .ant-checkbox-checked::after,
.custom-checkbox .ant-checkbox::after {
  border-color: #e12b25 !important;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #e12b25 !important;
}

/* date range picker */

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background-color: #e12b25 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #e12b25 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #e12b25 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #e12b25 !important;
}

.ant-btn-primary {
  background-color: #e12b25 !important;
  border-color: #e12b25 !important;
}

/* end date range picker */

.custom-radio {
  border-color: #d0d5dd !important;
}

.custom-radio .custom-radio-left {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.custom-radio .custom-radio-right {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.custom-radio .ant-radio-button-wrapper {
  border-color: #d0d5dd !important;
  outline: none !important;
  box-shadow: none !important;
}

.custom-radio .ant-radio-button-wrapper-checked {
  background-color: #f2f4f7 !important;
}

.custom-radio .ant-radio-button-wrapper-checked {
  background-color: #f2f4f7 !important;
}

.custom-radio .ant-radio-button-wrapper span {
  color: #101828 !important;
  font-size: 14px !important;
}

.custom-radio .ant-radio-button-wrapper:focus {
  border-color: #d0d5dd !important;
  outline: none !important;
  box-shadow: none !important;
}

.custom-radio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #d0d5dd !important;
}

/* spin */
.ant-spin-dot-item {
  background-color: orangered;
}

.ant-upload:hover {
  border-color: #e12b25 !important;
}

.custom-radio-btn .ant-radio-checked .ant-radio-inner:hover {
  outline: none !important;
  border-color: #e12b25 !important;
}

.custom-radio-btn .ant-radio-input:hover {
  outline: none !important;
  border-color: #e12b25 !important;
}

.custom-radio-btn::selection {
  background: #e12b25 !important;
}

.custom-radio-btn .ant-radio-checked .ant-radio-inner {
  border-color: #e12b25 !important;
  box-shadow: none !important;
}

.custom-radio-btn .ant-radio-checked .ant-radio-inner::after {
  background-color: #e12b25 !important;
}

.custom-radio-btn .ant-radio-wrapper:hover .ant-radio,
.custom-radio-btn .ant-radio:hover .ant-radio-inner,
.custom-radio-btn .ant-radio-input:focus + .ant-radio-inner {
  border-color: #e12b25 !important;
}

.ant-switch-checked {
  background-color: #e12b25;
}

/* react-slick */
.slick-list .slick-track {
  margin-left: 0;
}

.slick-list {
  margin: 0 -5px;
}

.rbc-button-link.rbc-show-more {
  color: #e12b25;
}
.rbc-button-link.rbc-show-more:hover {
  color: #e12b25;
}

.custom-radio-group .ant-radio-wrapper-checked .ant-radio-checked .ant-radio-inner {
  border-color: #e12b25 !important;
}

.custom-radio-group .ant-radio-wrapper .ant-radio:hover .ant-radio-inner {
  border-color: #e12b25 !important;
}

.custom-radio-group .ant-radio-wrapper .ant-radio .ant-radio-inner {
  box-shadow: none !important;
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  color: #fff;
}

.table-overflow-y .rdt_TableBody {
  height: 100%;
  max-height: 500px;
  overflow-y: auto;
}
