@import '~antd/dist/antd.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .custom-input-tailwind[type='number']::-webkit-inner-spin-button,
  .custom-input-tailwind[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.quill .ql-container .ql-editor i {
  /* font-style: 'italic' !important; */
  @apply !italic;
}

h1,
h2,
h3,
h4,
p,
a,
span {
  -webkit-text-size-adjust: none !important;
  text-size-adjust: none !important;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #ec6958 transparent;
  scroll-behavior: auto;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.5rem;
  overflow-y: auto;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #d0d5dd;
  border-radius: 14px;
}

@layer components {
  .display-xs-regular {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
  }

  .display-xs-medium {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  .display-xs-semibold {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }

  .display-xs-bold {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  .display-sm-regular {
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
  }

  .display-sm-medium {
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
  }

  .display-sm-semibold {
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
  }

  .display-sm-bold {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
  }

  .display-md-regular {
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
  }

  .display-md-medium {
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
  }

  .display-md-semibold {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
  }

  .display-md-bold {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
  }

  .display-lg-regular {
    font-weight: 400;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
  }

  .display-lg-medium {
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
  }

  .display-lg-semibold {
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
  }

  .display-lg-bold {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
  }

  .display-xl-regular {
    font-weight: 400;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.02em;
  }

  .display-xl-medium {
    font-weight: 500;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.02em;
  }

  .display-xl-semibold {
    font-weight: 600;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.02em;
  }

  .display-xl-bold {
    font-weight: 700;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.02em;
  }

  .display-2xl-regular {
    font-weight: 400;
    font-size: 72px;
    line-height: 90px;
    letter-spacing: -0.02em;
  }

  .display-2xl-medium {
    font-weight: 500;
    font-size: 72px;
    line-height: 90px;
    letter-spacing: -0.02em;
  }

  .display-2xl-semibold {
    font-weight: 600;
    font-size: 72px;
    line-height: 90px;
    letter-spacing: -0.02em;
  }

  .display-2xl-bold {
    font-weight: 700;
    font-size: 72px;
    line-height: 90px;
    letter-spacing: -0.02em;
  }

  .text-xs-regular {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  .text-xs-medium {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }

  .text-xs-semibold {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
  }

  .text-xs-bold {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
  }

  .text-sm-regular {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .text-sm-medium {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .text-sm-semibold {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .text-sm-bold {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }

  .text-md-regular {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .text-md-medium {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .text-md-semibold {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .text-md-bold {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .text-lg-regular {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  }

  .text-lg-medium {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }

  .text-lg-semibold {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }

  .text-lg-bold {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
  }

  .text-xl-regular {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
  }

  .text-xl-medium {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  .text-xl-semibold {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }

  .text-xl-bold {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
  }

  /* Effect Styles */
  .shadow-xs {
    background: #d9d9d9;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }

  .shadow-sm {
    background: #d9d9d9;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  }

  .shadow-md {
    background: #d9d9d9;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  }

  .shadow-lg {
    background: #d9d9d9;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  }

  .shadow-xl {
    background: #d9d9d9;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  }

  .shadow-2xl {
    background: #d9d9d9;
    box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
  }

  .shadow-3xl {
    background: #d9d9d9;
    box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);
  }

  .bg-blur-sm {
    background: #d9d9d9;
    backdrop-filter: blur(4px);
  }

  .bg-blur-md {
    background: #d9d9d9;
    backdrop-filter: blur(8px);
  }

  .bg-blur-lg {
    background: #d9d9d9;
    backdrop-filter: blur(12px);
  }

  .bg-blur-xl {
    background: #d9d9d9;
    backdrop-filter: blur(20px);
  }
}

input:checked + div {
  @apply border-primary-600 bg-primary-600;
}
input:checked + div svg {
  @apply block;
}

/* 
.custom-table-limit .ant-select-selector {
  border-radius: 6px !important;
  height: 36px !important;
  outline: none !important;
  box-shadow: none !important;
  border-color: #d0d5dd !important;
  display: flex;
  align-items: center;
} */

/* start schowing scrollbar dropdown */
.rc-virtual-list .rc-virtual-list-holder {
  display: block !important;
  overflow-y: scroll !important;
  cursor: pointer;
}

.rc-virtual-list-scrollbar {
  display: block !important;
  overflow-y: scroll !important;
  cursor: pointer;
  position: relative !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* end schowing section scrollbar dropdown */

/* start select option  */
.ant-select-selector {
  border-radius: 6px !important;
  height: 36px !important;
  outline: none !important;
  box-shadow: none !important;
  border-color: #d0d5dd !important;
  display: flex;
  align-items: center;
}

.ant-select-open .ant-select-selector,
.ant-select-focused .ant-select-selector {
  border-color: #e12b25 !important;
}

.ant-select-selector:focus,
.ant-select-selector:hover,
.ant-select-selector:active {
  border-color: #e12b25 !important;
  outline: none !important;
  box-shadow: none !important;
}

.ant-select-item-option-selected {
  background: #ffd6d6 !important;
}
.custom-collapse-layanan > .ant-collapse-item > .ant-collapse-header {
  @apply !pl-0 pr-4;
}
.custom-collapse-layanan > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  right: 0;
}
.custom-collapse-layanan > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  @apply !p-0;
}
/* end select option  */
